

export function handleFetchResponseStatus(response: Response): Promise<Response> {
    if (response.status === 401) {
        // Redirect to login page for unauthorized status
        window.location.href = '/login.html';
    }

    if (response.status >= 400 && response.status < 500) {
        // Handle other client errors
        return Promise.reject(new Error(`Client Error: ${response.statusText}`));
    }

    if (response.status >= 500) {
        // Handle server errors
        return Promise.reject(new Error(`Server Error: ${response.statusText}`));
    }

    // If response is OK, resolve the promise
    return Promise.resolve(response);
}

