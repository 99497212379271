import {FieldOperations, OpsCategories, SqlOperation, TableI} from "../../Types";
import {nanoid} from "@reduxjs/toolkit";
import moment from "moment";


let fieldOperations: FieldOperations = {
    last_change: [...OpsCategories.range],
    sent_viber: [...OpsCategories.eq],
    sent_email: [...OpsCategories.eq],
    msisdn: [...OpsCategories.eq, ...OpsCategories.like, ...OpsCategories.in,],
    dt: [...OpsCategories.range],
    id: [...OpsCategories.eq, ...OpsCategories.range, ...OpsCategories.in,],
};

export const BirthdayTable: TableI = {
    backend: "/287/",
    title: {
        ua: "Бонуси ДН",
    },
    pk: 'id',
    action: 'act287birthdayTab',
    defaultOrderedBy: "id",
    defaultOrderedWay: "DESC",
    defaultPerPage: 20,
    orderByFields: {
        "id": {ua: "ID"},
        "dt": {ua: "Створено"}
    },
    fieldOperations: fieldOperations,
    defaultFilters: 'birthday287DefFilters',
    fields: [
        {
            key: "n",
            inline: true,
            title: {ua: "#"},
            type: "number",
            applyTransform: true
        },
        {
            key: "msisdn",
            type: 'tel',
            applyTransform: true,
            detailed: true, inline: true,
            title: {ua: "Телефон",}
        },
        {
            key: 'sent_viber',
            type: "boolean",
            applyTransform: true,
            detailed: true, inline: true,
            title: {ua: "Viber"}
        },
        {
            key: 'sent_email',
            type: "boolean",
            applyTransform: true,
            detailed: true, inline: true,
            title: {ua: "Email"}
        },
        {
            key: "last_change",
            title: {ua: "Остання зміна"},
            type: "datetime",
            datetimeFormat: "DD MMMM YYYY HH:mm",
            applyTransform: true,
            inline: true,
            detailed: true
        },
        {
            key: "balls",
            detailed: true,
            inline: true,
            title: {ua: "Бали"},
            type: "number",
            applyTransform: true
        },
        {
            key: "dt",
            title: {ua: "Створено"},
            type: "datetime",
            datetimeFormat: "DD MMMM YYYY HH:mm",
            applyTransform: true,
            inline: false,
            detailed: true
        },
        {
            key: "id",
            detailed: true,
            title: {ua: "ID"},
            type: "number",
            applyTransform: true
        }
    ]
}

export function birthday287DefFilters() {
    return [ {
        nanoid: nanoid(),
        field: 'dt',
        type: 'datetime',
        filterOp: SqlOperation.GREATER_THAN_OR_EQUAL,
        locked: true,
        value: moment().startOf('year').format('yyyy-MM-DD HH:mm:ss')
    },]
}
