import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {Strings} from "../Types";
import {KeyOfIcons, KeyOfTimePeriods} from "../components/CONSTANTS";

interface GenericDataSliceI {
    [key: string]: { [key: string]: any }
}


export const genericDataSlice = createSlice({
    name: 'genericData',
    initialState: {
        act287sendViberSpam: {},
        act287birthday: {},
    } as GenericDataSliceI,
    reducers: {
        setGenericStateFeature: (state, action: PayloadAction<{
            feature: string,
            value: any
        }>) => {
            const {feature, value} = action.payload;
            state[feature] = value
        },
        updateGenericState: (state, action: PayloadAction<GenericDataPayloadI>) => {
            const {feature, property, value} = action.payload;

            // Ensure the feature root exists in the state
            if (!state[feature]) {
                state[feature] = {};
            }

            // Update the nested property using the helper function
            setNestedProperty(state[feature], property, value);
        },
    },
})

export const {updateGenericState,setGenericStateFeature} = genericDataSlice.actions

export default genericDataSlice.reducer

interface GenericDataPayloadI {
    feature: string,
    property: string,
    value: any
}


function setNestedProperty(obj: any, property: string, value: any) {
    const keys = property.split('.');
    let current = obj;

    for (let i = 0; i < keys.length - 1; i++) {
        const key = keys[i];

        // Check if the key is a valid array index
        const arrayIndex = parseInt(key, 10);
        if (!isNaN(arrayIndex)) {
            if (!Array.isArray(current)) {
                current = [];
            }
            if (!current[arrayIndex]) {
                current[arrayIndex] = {};
            }
            current = current[arrayIndex];
        } else {
            // Handle objects
            if (!current[key] || typeof current[key] !== 'object') {
                current[key] = {};
            }
            current = current[key];
        }
    }

    // Set the value at the final key
    const lastKey = keys[keys.length - 1];
    const arrayIndex = parseInt(lastKey, 10);
    if (!isNaN(arrayIndex)) {
        // Handle array index
        if (!Array.isArray(current)) {
            current = [];
        }
        current[arrayIndex] = value;
    } else {
        // Handle object key
        current[lastKey] = value;
    }
}
