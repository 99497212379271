import {useAppDispatch, useAppSelector} from "../../hooks";
import React, {useEffect} from "react";
import {API_BACK_URL} from "../../components/CONSTANTS";
import {handleFetchResponseStatus} from "../../components/util";
import {setGenericStateFeature} from "../../features/genericDataSlice";
import hljs from 'highlight.js/lib/core';
import styled from "styled-components";

const feature = 'act287birthday'

function BirthdayTab() {

    const data = useAppSelector(state => state.genericData[feature].data)
    const list = useAppSelector(state => state.genericData[feature].list as any[])
    const dispatch = useAppDispatch()

    useEffect(() => {
        if (data === undefined) {
            fetch(
                `${API_BACK_URL}/287/getBirthdayLogs.php`,
                {credentials: 'include'})
                .then(handleFetchResponseStatus)
                .then(r => r.text())
                .then(log => fetch(
                    `${API_BACK_URL}//runQuery.php?project=act287&query_key=getBirthdayList`,
                    {credentials: 'include'})
                    .then(handleFetchResponseStatus)
                    .then(r => r.json())
                    .then(r => dispatch(setGenericStateFeature({
                        feature: feature,
                        value: {data: log, list: r.data}
                    }))))

        }

    }, [dispatch, data]);

    if (data === undefined || list === undefined)
        return <div>Loading log...</div>

    let html = hljs.highlight(data, {language: "verilog"}).value;
    let code = <pre style={{height: '500px'}}><code
        style={{overflow: 'auto', height: '500px'}}
        dangerouslySetInnerHTML={{__html: html}}
        className={'language-json hljs'}></code></pre>

    return (
        <Container>
            <ul className="list">
                {list.map((r, i) => <li key={i}>
                    {r.birth_day_month}: {" "}{r.msisdn}
                </li>)}
            </ul>
            <div className="log">
                {code}
            </div>
        </Container>
    )
}

export default BirthdayTab

const Container = styled.div`
    display: flex;

    & .list {
        flex-shrink: 0;
        padding: .5rem;
        background: var(--table-row-odd-bg-color);
        color: var(--table-row-text-color);
        min-width: 240px;
        max-height: 500px;
        overflow-y: auto;


        @media (max-width: 1000px) {
            width: 100%;
        }

        & li{
            
            font-size: 1rem;
            font-weight: bold;
            font-family: monospace;
        }
    }

    & .log {
        flex-grow: 1;
        overflow-x: auto;
    }


    @media (max-width: 1000px) {
        flex-wrap: wrap;
    }
`
