import {useEffect, useState} from "react";
import {useAppDispatch} from "../../hooks";
import {createInitialStateForTables, createSchema} from "../../features/init";
import {STATIC_SCHEMAS} from "../../staticSchemas";
import {schemas} from "../../config";
import {setInitialTableState} from "../../features/tableSlice";
import {handleFetchResponseStatus} from "../util";
import {SchemaI} from "../../Types";
import {ProjectI, setIsUserAdmin, setProject} from "../../features/projectSlice";
import moment from "moment/moment";
import {toast} from "react-toastify";

export default function useSelectProject() {

    const dispatch = useAppDispatch();

    useEffect(() => {
        let project = localStorage.getItem('project') as any;
        if (project === null)
            window.location.href = '/login.html'

        project = JSON.parse(project)
        let actId = project['actId'];
        let index = projects.findIndex(x => x.id === actId);
        if (actId && index > -1) {
            dispatch(setProject(projects[index]))
            dispatch(setIsUserAdmin(project.userId))
        } else {
            toast.error('Такого проекту не знайдено...')
            setTimeout(() => {
                window.location.href = '/login.html'
            }, 6000)
        }
    }, [dispatch]);

}


const projects: ProjectI[] = [
    {
        name: 'act287',
        id: '287',
        isUserAdmin: false,
        iconsStyle: 'fontAwesome',
        activePage: 0,
        pages: [
            {
                name: 'table',
                lang: {
                    ua: 'Таблиці'
                },
                icon: 'table'
            },
            {
                name: 'summary',
                lang: {
                    ua: 'Сумарно'
                },
                func: 'Act287SummaryPage',
                icon: 'rectangleList'
            },
            {
                name: 'graph',
                lang: {
                    ua: 'Дошка'
                },
                disabled: false,
                icon: 'chartBar'
            }
        ],
    },
]
