import {
    Field,
    FieldMap,
    FieldOperations,
    FieldType,
    GroupSelectOption,
    OpsCategories,
    SelectOption,
    SqlOperation,
    TableI
} from "../../Types";
import moment from "moment/moment";
import {nanoid} from "@reduxjs/toolkit";
import {SELECT_BACK_COLOR} from "../../components/CONSTANTS";


const fieldOperations: FieldOperations = {
    id: [...OpsCategories.eq, ...OpsCategories.range, ...OpsCategories.in,],
    dt: [...OpsCategories.range],
    msisdn: [...OpsCategories.eq, ...OpsCategories.like, ...OpsCategories.in,],
    name: [...OpsCategories.like],
    email: [...OpsCategories.eq, ...OpsCategories.like],
    confirm: [...OpsCategories.eq],
    codes: [...OpsCategories.range],
    used: [...OpsCategories.range],
    referals_balls: [...OpsCategories.range, ...OpsCategories.null,],
    inviter_name: [...OpsCategories.like, ...OpsCategories.null],
    balls_extra: [...OpsCategories.eq, ...OpsCategories.range, ...OpsCategories.null,],
    level: [...OpsCategories.in],
};

export const userLevelMap = {
    groups: [{
        label: {
            ua: "Стартовий (0-2999)",
            en: "START"
        },
        values: ["START"]
    }, {
        label: {
            ua: "Середні (3000-6999)",
            en: "START"
        },
        values: ["SMART", "SMART A+"]
    }, {
        label: {
            ua: "Кінцеві (7000+)",
            en: "START"
        },
        values: ["GENIUS", "GENUS A+"]
    }],
    backgroundColor: SELECT_BACK_COLOR,
    values: {
        "START": {
            rawValue: "START",
            lang: {
                ua: "START",
                en: "START"
            },
            color: '#fc5f2a'
        },
        "SMART": {
            rawValue: "SMART",
            lang: {
                ua: "SMART",
                en: "SMART"
            },
            color: '#06d52b'
        }, "SMART A+": {
            rawValue: "SMART A+",
            lang: {
                ua: "SMART A+",
                en: "SMART"
            },
            color: '#1efcb5'
        },
        "GENIUS": {
            rawValue: "GENIUS",
            lang: {
                ua: "GENIUS",
                en: "GENIUS"
            },
            color: '#85aefb'
        },
        "GENUS A+": {
            rawValue: "GENUS A+",
            lang: {
                ua: "GENIUS A+",
                en: "GENIUS A+"
            },
            color: '#e9ff07'
        }
    }
} as FieldMap;

const userPhotoShareStatusMap = {
    groups: [{
        label: {
            ua: "Прийнято",
            en: "START"
        },
        values: ["accepted"]
    }, {
        label: {
            ua: "Відхилено",
            en: "START"
        },
        values: ["rejected:1", "rejected:2"]
    }],
    backgroundColor: '#202023',
    values: {
        "accepted": {
            rawValue: "accepted",
            lang: {
                ua: "Прийнято",
                en: "START"
            },
            color: '#32d922'
        },
        "rejected:1": {
            rawValue: "rejected:1",
            lang: {
                ua: "Відхилено (погана якість)",
                en: "SMART"
            },
            color: '#d5060d'
        }, "rejected:2": {
            rawValue: "rejected:2",
            lang: {
                ua: "Відхилено (не відповідає умовам)",
                en: "SMART"
            },
            color: '#d5060d'
        }
    }
} as FieldMap;

export function users287DefFilters() {
    return [
        {
            nanoid: nanoid(),
            field: 'dt',
            type: 'datetime',
            filterOp: SqlOperation.GREATER_THAN_OR_EQUAL,
            locked: true,
            value: moment().subtract(7, 'days').startOf('day').format('yyyy-MM-DD HH:mm:ss')
        },
        {
            nanoid: nanoid(),
            field: 'dt',
            type: 'datetime',
            filterOp: SqlOperation.LESS_THAN_OR_EQUAL,
            locked: true,
            value: moment().endOf('day').locale('en').format('yyyy-MM-DD HH:mm:ss')
        },
        {
            nanoid: nanoid(),
            field: 'msisdn',
            type: 'tel',
            filterOp: SqlOperation.LIKE,
            locked: true,
            value: ''
        },
        {
            nanoid: nanoid(),
            field: 'email',
            type: 'email',
            filterOp: SqlOperation.LIKE,
            locked: true,
            value: ''
        }
    ]
}

export const UsersTable: TableI = {
    backend: "/287/",
    title: {
        ua: "Користувачі",
        en: "Замовлення",
    },
    pk: 'id',
    defaultOrderedBy: "id",
    defaultOrderedWay: "DESC",
    defaultPerPage: 20,
    orderByFields: {
        "id": {ua: "ID", en: "№"},
        "dt": {ua: "Створено", ru: "Дата"},
        "level": {ua: "Рівень", ru: "Уровень"},
        "confirm": {ua: "Підтверджений", ru: "Подтвержденый"},
        "codes": {ua: "Коди", ru: "Подтвержденый"},
        "used": {ua: "Використано", ru: "Подтвержденый"},
        "balls_extra": {ua: "Додаткові бали", ru: "Подтвержденый"},
    },
    fieldOperations: fieldOperations,
    defaultFilters: 'users287DefFilters',
    fields: [
        {
            key: "n",
            inline: true,
            title: {ua: "№", ru: "№"}, type: "number",
            applyTransform: true
        }, {
            key: "id",
            detailed: true,
            title: {ua: "ID", ru: "№"},
            type: "number",
            applyTransform: true
        },
        {
            key: "dt",
            title: {ua: "Створено", ru: "Дата"},
            type: "datetime",
            datetimeFormat: "DD MMMM YYYY HH:mm",
            applyTransform: true,
            detailed: true,
            inline: false,
        },
        {
            key: "msisdn", type: 'tel',
            applyTransform: true,
            detailed: true, inline: true, title: {ua: "Телефон", ru: "Телефон"}
        },
        {
            key: "name",
            type: 'string',
            title: {ua: "П.І.Б.", ru: "Фамилия И.О."},
            editable: true,
            detailed: true,
            inline: true,
        },
        {
            key: "email",
            type: 'email',
            applyTransform: true,
            title: {ua: "Email", ru: "Email"},
            detailed: true,
            inline: true,
            editable: true,
        },
        {
            key: "level",
            title: {ua: "Рівень", ru: "Уровень"},
            detailed: true,
            inline: true,
            editable: true,
            applyTransform: true,
            type: "map",
            map: userLevelMap,
        },
        {
            key: "confirm",
            title: {ua: "Підтверджений", ru: "Подтвержденый"},
            type: "boolean",
            applyTransform: true,
            inline: true,
            detailed: true,
            editable: true,
        },
        {
            key: "codes",
            inline: true,
            detailed: true,
            title: {ua: "Коди", ru: "№"},
            type: "number",
            applyTransform: false
        }, {
            key: "used",
            inline: true,
            detailed: true,
            title: {ua: "Використано", ru: "№"},
            type: "number",
            applyTransform: false
        },
        {key: "free", title: {ua: "Вільних", ru: "Свободных"}, type: "number", inline: true},
        {
            key: "balls_extra",
            title: {ua: "Додаткові бали", ru: "Дополнительные балы"},
            type: "number",
            detailed: true,
            inline: false,
            editable: true,
            updateSingleFieldPath: 'updateUserBallsExtra.php'
        }, {
            key: "balls_extra_dt",
            title: {ua: "Час активації доп. балів", ru: "Дополнительные балы"},
            type: "datetime",
            applyTransform: true,
            datetimeFormat: "DD MMMM YYYY HH:mm",
            detailed: true,
        },
        {
            key: "ip",
            title: {ua: "IP Адреса", ru: "IP Адрес"},
            type: "string",
            detailed: true,
            inline: false,
            applyTransform: true,
        },
        {
            key: "password",
            title: {ua: "Пароль", ru: "Пароль"},
            type: "string",
            detailed: true,
            inline: false,
            applyTransform: true,
        },
        {
            key: "np",
            title: {ua: "Нова Пошта", ru: "Нова Пошта"},
            detailed: true,
            type: "string",
            inline: false,
            editable: true,
            applyTransform: true,
            backend: "updUserStringValue.php"
        },
        {
            key: "address",
            title: {ua: "Адреса", ru: "Адрес"},
            detailed: true,
            type: "string",
            inline: false,
            editable: true,
            applyTransform: true,
            backend: "updUserStringValue.php"
        },
        {
            key: "city",
            title: {ua: "Місто", ru: "Город"},
            type: "string",
            detailed: true,
            inline: false,
            editable: true,
            applyTransform: true,
            backend: "updUserStringValue.php"
        },
        {
            key: "birthday",
            title: {ua: "Дата народження", ru: "Дата нардження"},
            type: "string",
            detailed: true,
            inline: false,
            editable: true,
            applyTransform: true,
            backend: "updUserStringValue.php"
        },
        {
            key: "comment",
            title: {ua: "Коментар", ru: "Комментарий"},
            detailed: true,
            type: "string",
            editable: true,
            applyTransform: true,
            backend: "updUserStringValue.php"
        },
        {
            key: "motiv",
            title: {ua: "Мотивація", ru: "Модивация"},
            type: "boolean",
            detailed: true,
            applyTransform: true,
            inline: false
        },
        {
            key: "inviter_name", title: {ua: "Запрошувач", ru: "Пригласитель"}, type: "string",
            applyTransform: true,
        },
        {
            key: "referals",
            title: {ua: "Запрошувальні", ru: "Приглашенные"},
            type: "string",
            detailed: true,
            applyTransform: true,
            inline: false
        },
        {
            key: "referals_balls",
            title: {ua: "Балів від запрошувальних", ru: "Балов от приглашенных"},
            type: "number",
            detailed: true,
            inline: false
        },
        {
            key: "photo",
            title: {ua: "Фото", ru: "Фото"},
            type: 'image',
            imageMaxHeight: 400,
            detailed: true,
            inline: false,
            applyTransform: true
        },
        {
            key: "photo_share_1",
            title: {ua: "Фото 1 (Поділитися)", ru: "Фото 1 (Поділитися)"},
            detailed: true,
            inline: false,
            type: "image",
            imageMaxHeight: 300,
            applyTransform: true,
        },
        {
            key: "photo_share_2",
            title: {ua: "Фото 2 (Поділитися)", ru: "Фото 2 (Поділитися)"},
            detailed: true,
            inline: false,
            applyTransform: true,
            imageMaxHeight: 300,
            type: "image"
        },
        {
            key: "photo_share_3",
            title: {ua: "Фото 3 (Поділитися)", ru: "Фото 3 (Поділитися)"},
            detailed: true,
            inline: false,
            applyTransform: true,
            imageMaxHeight: 300,
            type: "image"
        },
        {
            key: "photo_share_status",
            title: {ua: "Статус (Поділитися)", ru: "Статус (Поділитися)"},
            detailed: true,
            editable: true,
            type: "map",
            applyTransform: true,
            map: userPhotoShareStatusMap,
            updateSingleFieldPath: 'updateUserPhotoShareStatus.php'
        },
        {
            key: "auth_adjuster_dt",
            title: {ua: "Авторизований монтажник", ru: "Авторизований монтажник"},
            type: "datetime",
            datetimeFormat: "DD MMMM YYYY HH:mm",
            applyTransform: true,
            detailed: true,
        }
    ] as Field[]
}


export interface FilterSchema {
    title: Record<string, string>
    type: FieldType
    operations: SqlOperation[],
    mapMulti?: boolean
    datetimeFormat?: string
    mapSchema?: MapFilterSchema
}


export interface MapFilterSchema {
    multiValue?: true,
    groups?: GroupSelectOption[]
    options?: SelectOption[]
    isColored: boolean
}

